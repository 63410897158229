import React from "react";
import { useState } from "react";
import { ref, set, onValue} from 'firebase/database';
import { ref as storage_ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { database } from '../firebase_config/firebase';
import { storage } from "../firebase_config/firebase";
import { useEffect } from "react";
import {showToast} from "../components/toast";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import success_image from '../images/checked.png'
import { UserAuth } from "../context/AuthContext";
import LoadingComponent from "../components/loading_backdrop";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Input, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { PaystackButton } from "react-paystack";
import CustomizedDialog from "../components/dialog";




const Econometrics = () =>{ 
    const [loading, setLoading] = useState(true);
    const [price, setPrice] = useState();
    const [amount, setAmount] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const amountInput = document.getElementById('amount');
    const [showDialog, setShowDialog] = useState(false);
    const [showProgressDialog, setShowProgressDialog] = useState(false);
    const navigate = useNavigate();
    const { user } = UserAuth();

    

    const [userdata, setUserData] = useState({
        first_name : '',
        last_name : '',
        email : '',
        phone_number : '',
        project_unique_name : '',
        project_unique_id : '',
        project_name : '',
        project_description : '',
    });

    const [fileDetails, setFileDetails] = useState({
        file: null,
        file_type: '',
      });


    
    const handleChange = (e) => {
        const { name, value } = e.target;

        const trimmedValue = name === 'project_unique_name' ? value.replace(/\s/g, '') : value;

        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: trimmedValue,
        }));
    };

      const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        if (file) {
          const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
          if (fileSizeInMB > 10) {
            showToast('File size must be 10MB or less', false);
            return;
          }
    
          // Update fileDetails state
          setFileDetails({
            file: file,
            file_type : file.type,
          });
    
          // Update userdata.project_name based on the file name
          setUserData((prevUserData) => ({
            ...prevUserData,
            project_name: file.name.substring(0, file.name.lastIndexOf(".")),
          }));
        }
      };
   

    useEffect(() => {
        const fetchData = async () => {
        try {
            const trendingRef = ref(database, "Trending/SecondaryData/Price");
            setLoading(true);

            onValue(trendingRef, (snapshot) => {
            const data = snapshot.val();

            setPrice(data);
            setAmount(data);

            setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
        };

        fetchData();
    }, []); 

    const manage_price = (which, event) => {
        // Prevent the default form submission behavior
        event.preventDefault();
    
        if (price !== null) {
            if (which === "half") {
                const half = parseInt(price) / 2;
                setAmount(half);
                document.getElementById("amount").value = half;
            } else {
                setAmount(price);
                document.getElementById("amount").value = parseInt(price);
            }
        }
    }

    function generateRandomChars() {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let randomChars = '';
        
        for (let i = 0; i < 6; i++) {
          const randomIndex = Math.floor(Math.random() * chars.length);
          randomChars += chars[randomIndex];
        }
        
        return randomChars;
    }



     const handlePriceChange = (e) => {
        const value = e.target.value;
        setAmount(value)
      };

    
 

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userdata.first_name === '') {
            showToast('Enter first name', false);
            return;
        }
        if (userdata.last_name === '') {
            showToast('Enter last name', false);
            return;
        }
        if (userdata.email === '') {
            showToast('Enter email address', false);
            return;
        }
        if (userdata.phone_number === '') {
            showToast('Enter mobile number', false);
            return;
        }
        if (userdata.project_unique_name === '') {
            showToast('Choose a unique name for this project', false);
            return;
        }
        if (userdata.project_name === '') {
            showToast('Upload project file', false);
            return;
        }

        if (userdata.project_unique_name.trim().length > 8) {
            showToast('Unique name should not be more than 8 characters', false);
            return;
        }
    

        if (amount < (price / 2)) {
            showToast('Initial amount must be up to half', false);
            return;
        }
        const project_unique_id = userdata.project_unique_name + generateRandomChars();
        setUserData((prevUserData) => ({
            ...prevUserData,
            project_unique_id: project_unique_id,
        }));
    
        handleOpenDialog();
      };

      const handleOpenDialog = () => {
        setIsDialogOpen(true);
      };
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };

      const generateTransactionReference = () => {
        const timestamp = Date.now().toString();
        const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
        return `REF-${timestamp}-${random}`;
      };

      const config = {
        reference: generateTransactionReference(),
        email: userdata.email,
        metadata: {
            "name": userdata.name,
            "phoneNumber": userdata.phone_number,
            "description": "QData Project Creation"
        },
        amount: parseFloat(amount * 100),
        publicKey: 'pk_test_3f1b6fba0eb935e643a8748acd5328da546e52dd',
    };
    
    const handleSuccess = (reference) => {
        handleCloseDialog();
        setShowDialog(true);
    };
    
    const handleClose = () => {
        console.log('closed');
    };

    const componentProps = {
        ...config,
        text: 'Pay with Paystack',
        onSuccess: (reference) => handleSuccess(reference),
        onClose: handleCloseDialog,
    };


     
    function getCurrentDateAsString() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
      
        const dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      
        return dateString;
      }

      const upload_project_file = async () => {
        const fileRef = storage_ref(storage, "UploadedProjectFiles/" + user.uid + "/" + userdata.project_unique_id + "/" + userdata.project_name);
    
        const metadata = {
            contentType: fileDetails.file_type
        };
    
        try {
            await uploadBytesResumable(fileRef, fileDetails.file, metadata);
            const url = await getDownloadURL(fileRef);
            return url;
        } catch (error) {
            console.error("Error uploading file:", error);
            // Handle error or return a default URL if necessary
            return null;
        }
    };
    

      const write_data_to_database = async () =>{
        try {
            setShowDialog(false)
            setShowProgressDialog(true);

            upload_project_file().then((url) => {
                if (url) {
                    const outstanding_debt = price - amount;
                    const project_data = {
                        email: userdata.email,
                        first_name: userdata.first_name,
                        last_name: userdata.last_name,
                        phone_number : userdata.phone_number,
                        unique_name: userdata.project_unique_name,
                        unique_id: userdata.project_unique_id,
                        project_name: userdata.project_name,
                        project_file_url : url,
                        project_extra_message: userdata.project_description,
                        status: "Awaiting Result",
                        outstanding_debt : outstanding_debt,
                        Date: getCurrentDateAsString(),
                        user_id: user.uid,
                    };
                
                  const questionnaireRef = ref(database, 'Users/' + user.uid + '/Projects/' + userdata.project_unique_id);
                  const awaitingLinksRef = ref(database, 'AwaitingResults/' + userdata.project_unique_id);
        
                  
              
                  set(questionnaireRef, project_data);
                  set(awaitingLinksRef, project_data);
              
                  const full_name = userdata.first_name + " " + userdata.last_name;
              
                  const htmlTemplate = `
                    <!DOCTYPE html>
                    <html>
                    <head>
                        <title>Task Created</title>
                        <style>
                            /* CSS styles for the email */
                            body {
                              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                              background-color: #f5f5f5;
                          }
                          .container {
                              max-width: 600px;
                              margin: 0 auto;
                              padding: 20px;
                              background-color: #ffffff;
                              border: 1px solid #dddddd;
                              border-radius: 4px;
                              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                          }
                          .logo {
                              text-align: center;
                              margin-bottom: 20px;
                          }
                          .logo img {
                              max-width: 100px;
                          }
                          .content {
                              margin-bottom: 30px;
                              font-size: 16px;
                              line-height: 1.5;
                          }
                          .footer {
                              text-align: center;
                              font-size: 12px;
                              color: #777777;
                              margin-top: 20px;
                              padding-top: 20px;
                              border-top: 1px solid #dddddd;
                          }
                          .footer img {
                              max-width: 80px;
                          }
                        </style>
                    </head>
                    <body>
                        <div class="container">
                            <div class="logo">
                                <img src="https://qdataapp.web.app/images/logo.png" alt="Company Logo">
                            </div>
                            <div class="content">
                                <p style="font-family: 'Arial', sans-serif; font-size: 20px;">Hello ${full_name},</p>
                                <p style="font-family: 'Arial', sans-serif;">This is to inform you that you've successfully created a task: ${userdata.project_unique_name} on QData.</p>
                                <p style="font-family: 'Arial', sans-serif;">We will now proceed to create a shareable URL for your data. We will notify you via email when it's ready.</p>
                                <p style="font-family: 'Arial', sans-serif;">If you have any questions or need further assistance, please feel free to reply to this email.</p>
                            </div>
                            <div class="footer">
                                <img src="https://qdataapp.web.app/images/qdata.png" alt="QData Logo"><br>
                                QData | +2347059148543 | qdata.app@gmail.com
                            </div>
                        </div>
                    </body>
                    </html>
                  `;
              
                  const data = {
                    recipient: userdata.email,
                    subject: "Task Created",
                    message: htmlTemplate
                  };
              
                 fetch('https://test-ma.onrender.com/send-email', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                  });
        
                  navigate(`/dashboard/${userdata.project_unique_id}`);
                  setShowProgressDialog(false);
                } else {
                    console.log("File upload failed.");
                    // Handle the case where the upload failed
                }
            });
            return;
            
           
      
        } catch (error) {
          showToast(error);
        }
      }
      

    return(
        <Box
            sx={{
                backgroundColor: "#0b146b",
                minHeight: '100vh',
            }}
        >

            <Container
                sx={{ 
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >

                
                <Box
                    sx={{
                        width: { xs: '100%', sm: '50%' },
                        display: 'flex',
                        mt: {xs: 2},
                        mb: {xs: 2}
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: {xs: '1.7rem', md: '3rem'},
                            color: 'white',
                            fontWeight: 'bold',
                            fontFamily: 'Fredoka One, sans-serif',
                            lineHeight: 1.2,
                            whiteSpace: 'pre-line',
                        }}
                    >
                        ECONOMETRICS{'\n'}PROJECT
                    </Typography>
                </Box>

                <Paper
                    sx={{
                        width: {xs: '100%', sm: '50%'},
                        m: 2,
                        p:2
                    }}
                >
                    <Box sx={{m:2}}>
                        <TextField
                            name="first_name"
                            type="text"
                            value={userdata.first_name}
                            placeholder="First Name"
                            autoFocus
                            required
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Box>

                    <Box sx={{m:2}}>
                        <TextField
                            name="last_name"
                            type="text"
                            value={userdata.last_name}
                            placeholder="Last Name"
                            autoFocus
                            required
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Box>

                    <Box sx={{m:2}}>
                        <TextField
                            name="email"
                            type="email"
                            value={userdata.email}
                            placeholder="Email"
                            autoFocus
                            required
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Box>

                    <Box sx={{m:2}}>
                        <TextField
                            name="phone_number"
                            type="tel"
                            value={userdata.phone_number}
                            placeholder="Phone Number"
                            autoFocus
                            fullWidth
                            required
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Box>

                    <Box sx={{m:2}}>
                        <TextField
                            name="project_unique_name"
                            type="text"
                            value={userdata.project_unique_name}
                            placeholder="Unique Name"
                            autoFocus
                            required
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Box>

                    <Box
                        sx={{
                            m: 2,
                            bgcolor: '#fff',
                            border: '2px dashed #ccc',
                            borderRadius: '4px',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            variant="text"
                            component="label"
                            startIcon={<span style={{ fontSize: '24px' }}>📎</span>}
                            sx={{ fontSize: '16px', mb: 2 }}
                        >
                            Upload File (PDF or DOCX, max 10MB)
                            <Input
                                type="file"
                                accept=".pdf, .docx"
                                onChange={handleFileChange}
                                sx={{ display: 'none' }}
                            />
                        </Button>
                        {fileDetails.file && (
                            <Typography variant="body2" sx={{ color: '#4caf50', mt: 1 }}>
                                {fileDetails.file.name}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{m:2}}>
                        <TextField
                            id="project_description"
                            name="project_description"
                            placeholder="Is there any other thing you will like to tell us concerning this project?"
                            multiline
                            rows={4}
                            value={userdata.project_description}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            m: 2,
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                flexBasis: '70%',
                                mr: 1
                            }}
                        >
                            <Input
                                type="number"
                                onChange={handlePriceChange}
                                step="any"
                                id="amount"
                                sx={{
                                    pl: '30px',
                                }}
                                value={amount}
                                fullWidth
                                startAdornment={<InputAdornment position="start">₦</InputAdornment>}
                            />
                        </Box>
                        <Box 
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexBasis: '30%'
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={(e) => manage_price('half', e)}
                                sx={{ mr: 1, bgcolor: '#d1ad0f'}}
                            >
                                HALF
                            </Button>
                            <Button
                                variant="contained"
                                onClick={(e) => manage_price('max', e)}
                                sx={{bgcolor: '#29820c'}}
                            >
                                MAX
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{m:2}}>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{
                                width: '100%',
                                backgroundColor: '#0b146b',
                                fontSize: '16px',
                                borderRadius: '4px',
                                '&:hover': {
                                    borderColor: '#081247',
                                },
                                '&:focus': {
                                    borderColor: '#081247',
                                    outline: 'none',
                                },
                            }}
                        >
                            ROLLOUT
                        </Button>
                    </Box>

                </Paper>


            </Container>

            <ToastContainer/>
            {loading && <LoadingComponent/>}

            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogContent  sx={{ display: 'flex', alignItems: 'center'}}>
                    <PaystackButton {...componentProps} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                    Close
                    </Button>
                </DialogActions>
            </Dialog>

            {showDialog && <CustomizedDialog 
                imageUrl={success_image}
                message="Payment Successful || Click continue to proceed"
                buttonText="Continue"
                onClose={write_data_to_database} />
            }

            {showProgressDialog && <LoadingComponent msg='creating project...'/>}


        </Box>
    )
}

export default Econometrics;