import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

function ProjectWriting() {
  return (
    <Box sx={{ minHeight: '100vh'}}>
        <NavBar/>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                flexGrow: 1,
                px: 2,
                py: 8,
                gap: 2,
                mt: 3,


            }}
        >
            <Typography
            variant="h5"
            component="h1"
            sx={{
                fontWeight: 'medium',
                mb: 2,
                fontFamily: 'Fredoka One, sans-serif',
            }}
            >
                Introducing Qs'AI!
            </Typography>

            <Typography
            variant="body1"
            sx={{
                maxWidth: '800px',
                mb: 3,
                lineHeight: 1.8
            }}
            >
            We're proud to announce a breakthrough in academic project development: an AI-powered Project Writing Assistant designed specifically for students. Through years of dedicated research and development, we've created an innovative tool that understands the unique requirements of academic projects and guides students through the writing process.
            </Typography>

            <Typography
            variant="body1"
            sx={{
                maxWidth: '800px',
                mb: 4,
                lineHeight: 1.8
            }}
            >
            While we're preparing for public release, this powerful tool is currently available exclusively through our team of academic experts. Experience the future of project writing today!
            </Typography>

            <Button
            variant="contained"
            size="large"
            startIcon={<WhatsAppIcon />}
            onClick={() => window.open('https://wa.me/2347059148543', '_blank')}
            sx={{
                backgroundColor: '#25D366',
                '&:hover': {
                backgroundColor: '#128C7E'
                },
                borderRadius: 2,
                py: 1.5,
                px: 4,
                textTransform: 'none',
                fontSize: '1.1rem'
            }}
            >
            Start Your Project Now
            </Button>
        </Box>
        <Footer/>
    </Box>
  );
}

export default ProjectWriting;