import { Box, Typography, Link } from "@mui/material";

function QsAi() {
    return (
        <Box
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50px',
                padding: '0 16px',
                gap: '8px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography
                sx={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#333',
                }}
            >
                Introducing Q'sAi 🎉
            </Typography>
            <Link
                href="/write-project" // Replace with the actual link
                sx={{
                    fontSize: '1rem',
                    textDecoration: 'underline',
                    color: '#007BFF',
                    '&:hover': {
                        textDecoration: 'none',
                    },
                }}
            >
                Learn More
            </Link>
        </Box>
    );
}

export default QsAi;
