import React from "react";
import { ref, child, get} from 'firebase/database';
import { database } from '../firebase_config/firebase';
import warning_image from '../images/warning.png';
import Dialog from "../components/dialog";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SideNav from "../components/side_nav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faEye, faUpload, faFlask, faChartLine, faProjectDiagram, faBook } from '@fortawesome/free-solid-svg-icons';
import FloatingActionButton from "../components/fab";
import ChatIcon from '@mui/icons-material/Chat';
import { UserAuth } from "../context/AuthContext";
import { ToastContainer } from "react-toastify";
import showToast from "../components/toast";
import LoadingComponent from "../components/loading_backdrop";
import { Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CardItem from "../components/card-item";

const CreateProject = () =>{
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const trendingRef = ref(database, 'Trending');
    const {user} = UserAuth();
    const [acceptingParams, setAcceptingParams] = useState({
      'regular' : true,
      'sensory' : true,
      'lab' : true,
      'customized' : true,
      'econometrics' : true
    })

    useEffect(() => {
      let isMounted = true;
    
      const get_accepting_params = async () => {
        try {
          const trendingRef = ref(database, 'Trending');
    
          const snapshot = await get(child(trendingRef, 'RegularQuestionnaires/IsAccepting'));
          const regular_value = snapshot.val();
    
          const snapshot1 = await get(child(trendingRef, 'Sensory Evaluation/IsAccepting'));
          const sensory_value = snapshot1.val();
    
          const snapshot2 = await get(child(trendingRef, 'LabAnalysis/IsAccepting'));
          const lab_value = snapshot2.val();
    
          const snapshot3 = await get(child(trendingRef, 'UploadFile/IsAccepting'));
          const upload_value = snapshot3.val();
    
          const snapshot4 = await get(child(trendingRef, 'SecondaryData/IsAccepting'));
          const econometrics_value = snapshot4.val();
    
          if (isMounted) {
            setAcceptingParams((prevAcceptingParams) => ({
              ...prevAcceptingParams,
              regular: regular_value,
              sensory: sensory_value,
              lab: lab_value,
              customized: upload_value,
              econometrics: econometrics_value
            }));
            setIsLoading(false);
          }
    
        } catch (error) {
          if (isMounted) {
            showToast(error.message);
            setIsLoading(false);
          }
        }
      };
    
      get_accepting_params();
    
      // Cleanup function
      return () => {
        isMounted = false;
      };
    
    }, [user.uid]);
    

    const checkAcceptingRegular = () => {
      
      if (acceptingParams.regular === false) {
        setShowDialog(true);
      } else {
        navigate('/create/regular');
      }
    };

    const checkAcceptingSensory = () => {
      if (acceptingParams.sensory === false) {
        setShowDialog(true);
      } else {
        navigate('/create/sensory-evaluation');
      }
    };

    const checkAcceptingLabAnalysis = () => {
      if (acceptingParams.lab === false) {
        setShowDialog(true);
      } else {
        navigate('/create/lab-analysis');
      }
    };

    const checkAcceptingCustomized = () => {
      if (acceptingParams.customized === false) {
        setShowDialog(true);
      } else {
        navigate('/create/customized-project');
      }
    };

    const checkAcceptingEconometrics = () => {
      if (acceptingParams.econometrics === false) {
        setShowDialog(true);
      } else {
        navigate('/create/econometrics');
      }
    };
   



    return(
        <Container>
          <SideNav/>

          {isLoading && <LoadingComponent/>}

          {!isLoading && 
            <FloatingActionButton whatsapp={true}/>
          }
          {!isLoading &&
            <Grid2 container sx={{mt:8}} spacing={2}>

              {/* <Grid2 item xs={6} md={4} >
                <CardItem img={faQuestionCircle} title="Regular Questionnaire" color="#5b9cf1" onClick={checkAcceptingRegular}/>
              </Grid2> */}

              <Grid2 item xs={6} md={4}>
                <CardItem img={faUpload} title="Customized Project" color="#0bd64f" onClick={checkAcceptingCustomized}/>
              </Grid2>


              <Grid2 item xs={6} md={4}>
                <CardItem img={faEye} title="Sensory Evaluation" color="#9b3b8e" onClick={checkAcceptingSensory}/>
              </Grid2>

              {/* <Grid2 item xs={6} md={4}>
                <CardItem img={faFlask} title="Laboratory Analysis" color="#8B0000" onClick={checkAcceptingLabAnalysis}/>
              </Grid2> */}

              <Grid2 item xs={6} md={4}>
                <CardItem img={faBook} title="Project Writting" color="#8B0000" onClick={() => navigate('/write-project')}/>
              </Grid2>

              <Grid2 item xs={6} md={4} >
                <CardItem img={faChartLine} title="Econometrics" color="#ad9d06" onClick={checkAcceptingEconometrics}/>
              </Grid2>
            </Grid2>
          }
         
          {showDialog && <Dialog 
            imageUrl={warning_image}
            message="Sorry, we are currently not acceppting from this category"
            buttonText="Dismiss"
            onClose={() => setShowDialog(false)} />
          }

          <ToastContainer/>
        </Container>
    )
}

export default CreateProject;