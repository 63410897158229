import React, { useState } from "react";
import qdataLogo from '../images/logo.png';
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ContactMail, Home, Info, Login, Menu, PersonAdd, QuestionAnswer, RateReview, Star } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import QsAi from "./qsai";


function NavBar(){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);

    const toggleSideBar = () =>{
        setOpen(!open);
    }

    const ListItemStyled = styled(ListItem)(({ theme }) => ({
        color: theme.palette.grey[400],
        borderRadius: '8px',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        padding: '12px 16px',
    }));

    const scrollToSection = (section) => {
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        toggleSideBar(); // Close the drawer after navigating
    };

    return (
        <Box bgcolor="grey.900">
            <AppBar position="relative">

                <Toolbar 
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'grey.900'
                    }}
                >

                    <Box 
                        sx={{ 
                            flexGrow: 1, 
                            display: "flex", 
                            flexDirection: 'row', 
                            alignItems: 'center'
                        }}
                    >
                        <img 
                            src={qdataLogo} 
                            style={{ 
                                width: isMobile ? '35px' : '40px',
                                height: isMobile ? '35px' : '40px'
                            }}
                        />
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: isMobile ? '25px' : '30px',
                                color: 'grey.400'
                            }}
                        >
                            DATA
                        </Typography>
                    </Box>

                    <List sx={{ display: isMobile ? 'none' : 'flex', flexDirection: 'row', padding: 0 }}>
                        <ListItemStyled component="a" href="/#home">
                            <ListItemText>Home</ListItemText>
                        </ListItemStyled>

                        <ListItemStyled component="a" href="/#about">
                            <ListItemText>About</ListItemText>
                        </ListItemStyled>

                        <ListItemStyled component="a" href="/#highlights">
                            <ListItemText>Highlights</ListItemText>
                        </ListItemStyled>

                        <ListItemStyled component="a" href="/#testimonials">
                            <ListItemText>Testimonials</ListItemText>
                        </ListItemStyled>

                        <ListItemStyled component="a" href="/#contact">
                            <ListItemText>Contact</ListItemText>
                        </ListItemStyled>

                        <ListItemStyled component="a" href="/#faq">
                            <ListItemText>FAQ</ListItemText>
                        </ListItemStyled>

                        {/* <ListItemStyled>
                            <ListItemText>Login</ListItemText>
                        </ListItemStyled>

                        <ListItemStyled>
                            <ListItemText>Register</ListItemText>
                        </ListItemStyled> */}
                    </List>

                    <IconButton sx={{ display: isMobile ? 'flex' : 'none' }} onClick={toggleSideBar}>
                        <Menu sx={{ color: 'grey.400'}}/>
                    </IconButton>

                    <Drawer
                        variant="temporary"
                        anchor="right"
                        open={open}
                        onClose={toggleSideBar}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: 240,
                                boxSizing: 'border-box',
                                backgroundColor: 'grey.900',
                            },
                        }}
                    >
                        <Box sx={{ p: '16px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <List sx={{ flexGrow: 1 }}>
                                <ListItemStyled onClick={() => scrollToSection('home')}>
                                    <ListItemIcon>
                                        <Home sx={{ color: 'grey.400' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Home" />
                                </ListItemStyled>

                                <ListItemStyled onClick={() => scrollToSection('about')}>
                                    <ListItemIcon>
                                        <Info sx={{ color: 'grey.400' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="About" />
                                </ListItemStyled>

                                <ListItemStyled onClick={() => scrollToSection('highlights')}>
                                    <ListItemIcon>
                                        <Star sx={{ color: 'grey.400' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Highlights" />
                                </ListItemStyled>

                                <ListItemStyled onClick={() => scrollToSection('testimonials')}>
                                    <ListItemIcon>
                                        <RateReview sx={{ color: 'grey.400' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Testimonials" />
                                </ListItemStyled>

                                <ListItemStyled onClick={() => scrollToSection('contact')}>
                                    <ListItemIcon>
                                        <ContactMail sx={{ color: 'grey.400' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Contact" />
                                </ListItemStyled>

                                <ListItemStyled onClick={() => scrollToSection('faq')}>
                                    <ListItemIcon>
                                        <QuestionAnswer sx={{ color: 'grey.400' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="FAQ" />
                                </ListItemStyled>
                            </List>
                        </Box>
                    </Drawer>

                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default NavBar;