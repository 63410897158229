import React, { useEffect, useState } from "react";
import { ref, set, get, onValue, query} from 'firebase/database';
import { database } from '../firebase_config/firebase';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEye } from '@fortawesome/free-solid-svg-icons'
import LoadingComponent from "../components/loading_backdrop";
import SideNav from "../components/side_nav";
import FloatingActionButton from "../components/fab";
import showToast from "../components/toast";
import { ToastContainer } from "react-toastify";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CardItem from "../components/card-item";
import QsAi from "../components/qsai";


const Home = () =>{
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
        const usersRef = ref(database, 'Users/' + user.uid + '/Projects');
      
        const get_projects = async () => {
          try {
            const snapshot = await get(query(usersRef));
      
            if (!snapshot.exists()) {
              // Database reference is empty
              if (isMounted) {
                setProjects([]);
                setIsLoading(false);
              }
              return;
            }
      
            const projectsArray = [];
      
            snapshot.forEach((childSnapshot) => {
              const childData = childSnapshot.val();
              const project_unique_name = childData.unique_name;
              const project_unique_id = childData.unique_id;
      
              projectsArray.push({
                uniqueName: project_unique_name,
                uniqueId: project_unique_id,
              });
            });
      
            if (isMounted) {
              setProjects(projectsArray);
              setIsLoading(false);
            }
      
          } catch (error) {
            if (isMounted) {
              showToast(error.message);
              setIsLoading(false);
            }
          }
        };
      
        get_projects();
      
        // Cleanup function
        return () => {
          isMounted = false;
        };
      
      }, [user.uid]);
      
    
    const handleCardClick = (uniqueId) =>{
        navigate(`/dashboard/${uniqueId}`);
    }

   
      
    return (
        <Box>
           <SideNav/>
            {isLoading && <LoadingComponent/>}

            {!isLoading && 
        
              <FloatingActionButton whatsapp={true}/>
      
            }

          
            <Container>

              {!isLoading && projects.length === 0 && 

                <Box sx={{mt: 8}}>
                  <CardItem img={faPlus} title="Get Started By Creating A New Project" onClick={() => navigate('/create')} />  
                </Box>
              }

              <Grid2 container sx={{mt: 10}} spacing={2}>
                {!isLoading && projects.length !== 0 && projects.map((project, index) => (
                  <Grid2 item xs={12} md={6}>
                    <Card
                        key={index}
                        onClick={() => handleCardClick(project.uniqueId)}
                        sx={{
                            borderRadius: '8px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                            '&:hover': {
                                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                                transform: 'translateY(-2px)',
                            },
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography
                                    sx={{
                                        fontFamily: '"Poppins", sans-serif',
                                        color: '#333333',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        marginRight: '8px',
                                    }}
                                >
                                    {project.uniqueName}
                                </Typography>
                            </Box>
                            <FontAwesomeIcon
                                icon={faEye}
                                color="#081247"
                                sx={{
                                    fontSize: '20px',
                                }}
                            />
                        </CardContent>
                    </Card>

                  </Grid2>
                ))}
              </Grid2>
              
            </Container>
              
            <ToastContainer/>
        </Box>
    )
}

export default Home;