import React from 'react';
import {
  Dialog as MuiDialog,
  DialogContent,
  Typography,
  Button,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const CustomizedDialog = ({ imageUrl, message, buttonText, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiDialog 
      open 
      fullScreen={fullScreen}
      PaperProps={{
        style: { 
          padding: '20px', 
          borderRadius: '10px',
          textAlign: 'center',
          margin: '0 20px',
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {imageUrl && (
            <Box
              component="img"
              src={imageUrl}
              alt="Dialog Icon"
              sx={{
                width: 50,
                height: 50,
                mb: 2
              }}
            />
          )}

          <Typography
            variant="body1"
            sx={{
              mb: 3,
              fontSize: '15px',
              lineHeight: 1.5,
              fontFamily: "'Times New Roman', Times, serif"
            }}
          >
            {message}
          </Typography>

          <Button
            fullWidth
            onClick={onClose}
            variant="contained"
            sx={{
              backgroundColor: '#081247',
              color: '#ffffff',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '5px',
            }}
          >
            {buttonText || 'Close'}
          </Button>
        </Box>
      
        
      </DialogContent>
    </MuiDialog>
  );
};

export default CustomizedDialog;